<template>
  <page-content>
    <page-title heading="รายงานสรุปค่าสอนครู">
      <template v-slot:right>
        <b-button variant="danger" @click="exportReport">
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงานสรุป</span>
        </b-button>

        <!-- <download-excel
          class="btn btn-danger cursor-pointer"
          :fields="exportFields"
          :fetch="fetchExport"
          name="รายงานสรุปค่าสอนครู.xls"
        >
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงานสรุป</span>
        </download-excel> -->
      </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master>

        <!-- <filter-master
          v-model="formfilter"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master> -->
      </b-col>

      <b-col cols="10" md="6" class="mt-2 pr-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อครูหรือรายชื่อโรงเรียน"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="2" class="mt-2 pl-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <report-transaction-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="TableParams"
      @seeDetail="onSeeDetail"
      @delete="onDelete"
    />

    <create-transection
      v-model="showModal"
      :transactionId="transactionId"
      isReadOnly
    ></create-transection>
  </page-content>
</template>

<script>
// import DownloadExcel from "vue-json-excel";
import { groupBy, orderBy, sumBy } from "lodash";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import CreateTransection from "../../components/modal/CreateTransection";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
// import CreateOrUpdateUser from "../../components/modal/CreateOrUpdateUser";
import ReportTransactionTable from "../../components/table/ReportTransaction";
import FilterMaster from "../../components/form/FilterMaster";
// import DeleteUser from "../../components/modal/DeleteUser";
import { Auth } from "../../models";
import reportMixin from "../../mixins/reportMixin";

export default {
  mixins: [reportMixin],

  components: {
    PageContent,
    PageTitle,
    CreateTransection,
    ReportTransactionTable,
    FilterMaster,
    // DownloadExcel,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      deleteData: {},
      transactionId: null,
      isExporting: false,
      showModal: false,
      showModalDelete: false,
      exportFields: {
        // ภาค: "headquarter",
        // กองบังคับการ: "division",
        สถานี: "station",
        "ยศ ชื่อ-สกุล": "user_name",
        // เลขประจำตัวผู้ขอ: "user_id_governmen",
        ปีการศึกษา: "yearTerm",
        โรงเรียน: "school",
        // "จำนวนนักเรียน (คน)": "sum_student",
        "จำนวนนักเรียนตามแผน (คน)": "sum_plan_student",
        "จำนวนห้องเรียน (ห้อง)": "sum_class",
        "ค่าตอบแทน (บาท)": "sumPayment",
      },
      colKey: "ยศ ชื่อ-สกุล",
      exportOrders: ["user_name", "yearTerm", "station", "school"],
      exportOrderDirections: ["asc", "desc", "asc", "asc"],
    };
  },

  // watch: {
  //   formfilter: {
  //     deep: true,
  //     handler: "onFormFilterChanged",
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      const fields = [
        { key: "order", label: "ลำดับ" },
        { key: "headquarter", label: "ภาค" },
        { key: "division", label: "จังหวัด" },
        { key: "station", label: "สถานี" },
        { key: "user_name", label: "ยศ ชื่อ-สกุล" },
        { key: "yearTerm", label: "ปีการศึกษา" },
        { key: "school", label: "โรงเรียน" },
        {
          key: "sum_class",
          label: "จำนวนห้องเรียน (ห้อง)",
          class: "text-center",
        },
        // {
        //   key: "sum_student",
        //   label: "จำนวนนักเรียน (คน)",
        //   class: "text-center",
        // },
        {
          key: "sum_plan_student",
          label: "จำนวนนักเรียนตามแผน (คน)",
          class: "text-center",
        },
        { key: "sumPayment", label: "ค่าตอบแทน (บาท)", class: "text-right" },
      ];

      return fields;
    },

    TableParams() {
      const { formfilter } = this;

      return {
        ...formfilter,
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    async fetchExport() {
      let data = [];

      this.isExporting = true;

      try {
        data = await this.$refs.table.fetch({ downloadAll: true });
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลรายงานสรุปได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isExporting = false;
      }

      return data;
    },

    async exportReport() {
      let data = await this.fetchExport();

      let exportFields = { ...this.exportFields };

      if (this.formfilter.mStationId) {
        this.$delete(exportFields, "สถานี");
      }

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      if (data && data.length) {
        let groupedData = {};

        if (this.formfilter.mStationId) {
          groupedData = groupBy(data, "station");
        } else {
          groupedData = groupBy(data, "division");
        }

        let sheetHeader = ["ลำดับ", ...header];

        for (const groupName in groupedData) {
          if (Object.hasOwnProperty.call(groupedData, groupName)) {
            let groupRecords = groupedData[groupName];

            if (groupRecords && groupRecords.length) {
              groupRecords = orderBy(
                groupRecords,
                this.exportOrders,
                this.exportOrderDirections
              );

              groupRecords = groupRecords.map((record, index) => {
                const res = {
                  ลำดับ: index + 1,
                };

                header.forEach((col) => {
                  res[col] = record[exportFields[col]];
                });

                return res;
              });
            }

            let ws = XLSX.utils.json_to_sheet(groupRecords, {
              header: sheetHeader,
            });

            ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
              orderCol: "A",
              mergedCol: {
                "จำนวนห้องเรียน (ห้อง)": (records) => {
                  return sumBy(records, "v");
                },
                "ค่าตอบแทน (บาท)": (records) => {
                  return sumBy(records, "v");
                },
              },
            });

            ws = this.$_reportMixin_setWidthColumn(
              ws,
              sheetHeader,
              groupRecords
            );

            // const ws = XLSX.utils.json_to_sheet(groupRecords, {
            //   header: ["ลำดับ", ...header],
            // });

            XLSX.utils.book_append_sheet(wb, ws, groupName);
          }
        }
      }

      // const ws = XLSX.utils.json_to_sheet(data, {
      //   header: ["ลำดับ", ...header],
      // });

      // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      /* bookType can be any supported output type */
      const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

      const wbout = XLSX.write(wb, wopts);

      /* the saveAs call downloads a file on the local machine */
      saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        "รายงานสรุปค่าสอนครู.xlsx"
      );
    },

    async onSeeDetail(item) {
      this.transactionId = item.id;
      this.showModal = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
