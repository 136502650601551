<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :action-label="actionLabel"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :per-page="10"
        :show-action="false"
      >
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, TeachingDare } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "เพิ่ม/แก้ไข",
    },
    actionLabel: {
      type: String,
      default: "เพิ่ม/แก้ไขข้อมูล",
    },
    seeDetail: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        additionalParams = {},
        numberOfPages = 1;

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "mStationId", this.params.mStationId);
      }

      this.isFetching = true;

      try {
        const params = {};

        if (!ctx.downloadAll) {
          this.$set(params, "limit", ctx.perPage);
          this.$set(params, "offset", (ctx.currentPage - 1) * ctx.perPage);
        }

        // if (!this.authUser.isAdminType) {
        //   this.$set(params, "userProfileId", this.authUser.id);
        // }

        // MRank.api().findAll();

        const promise = await TeachingDare.api().getReportPayment(
          {
            ...params,
            ...additionalParams,
          },
          { save: false }
        );

        let { data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        data = responseData.map((record) => {
          let user_name = "",
            school = "",
            user_id_governmen = "",
            headquarter = "",
            division = "",
            station = "";

          let yearTerm = record.fiscal_year || "";

          if (record.term) {
            yearTerm += `/${record.term}`;
          }

          if (record.m_school) {
            school = record.m_school.m_school_name;
          }
          //   let classRoom = "";

          //   if (record.m_class) {
          //     classRoom += record.m_class.m_class_name;

          //     const roomNumber = parseInt(record.room);

          //     if (!isNaN(roomNumber)) {
          //       classRoom += `/${roomNumber}`;
          //     }
          //   }

          if (record.user_profile) {
            const userProfile = record.user_profile;
            const userRank = userProfile.m_rank;

            user_name = `${userProfile.first_name} ${userProfile.last_name}`;

            user_id_governmen = userProfile.id_governmen;

            if (userRank) {
              user_name = `${userRank.m_rank_name} ${user_name}`;
            }

            if (record.m_station) {
              station = record.m_station.m_station_name;

              if (record.m_station.m_division) {
                division = record.m_station.m_division.m_division_name;

                if (record.m_station.m_division.m_headquarter) {
                  headquarter =
                    record.m_station.m_division.m_headquarter
                      .m_headquarters_name;
                }
              }
            }
          }

          return {
            ...record,
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
            yearTerm,
            user_name,
            user_id_governmen,
            headquarter,
            division,
            station,
            school,
            total_time: record.total_time ? parseInt(record.total_time) : 0,
            sum_class: record.sum_class ? parseInt(record.sum_class) : 0,
            sum_student: record.sum_student ? parseInt(record.sum_student) : 0,
            sum_plan_student: record.sum_plan_student
              ? parseInt(record.sum_plan_student)
              : 0,
            sumPayment: record.sumPayment ? parseInt(record.sumPayment) : 0,
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      if (!ctx.downloadAll) {
        this.numberOfPages = numberOfPages;
      }

      return data;
    },

    onSeeDetail(item) {
      this.$emit("seeDetail", item);
    },

    editTeaching(item) {
      this.$emit("edit", item);
    },

    deleteTeaching(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
